/* You can add global styles to this file, and also import other style files */
@import '../../../node_modules/@qts-sdp-ui/shared-ui/lib/styles/reset';
@import '../../../node_modules/@qts-sdp-ui/shared-ui/lib/styles/main';
@import '../../../node_modules/@qts-sdp-ui/shared-ui/lib/styles/colors';

::-webkit-scrollbar {
  width: 16px;
  // background-color: hsl(0, 82%, 69%)
}
::-webkit-scrollbar-thumb {
  height: 50px;
  border-radius: 8px;
  border: 4px solid transparent;
  background-clip: content-box;
  background-color: var(--color-utility-40);
}

.main-container {
  &.page-container {
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
    min-height: calc(100vh - (69px + 126px));
    @include page-container;
  }

  .table-wrapper {
    margin-top: var(--spacing-xxs);

    .table-container {
      overflow: hidden;
    }

    .pagination-container {
      margin-top: var(--spacing-xs);

      @media screen and (min-width: #{$tablet-min}) {
        margin-top: var(--spacing-l);
      }
    }

    @media screen and (min-width: #{$tablet-min}) {
      margin-top: var(--spacing-l);
    }
  }
}
